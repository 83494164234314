//
// ProjectCard.tsx
//

import DeleteProject from "@components/Projects/DeleteProject";
import EditProject from "@components/Projects/EditProject";
import SingleProjectInfo from "@components/Projects/SingleProjectInfo";
import { useGetProjectJobs } from "@custom-hooks/Jobs";
import {
  convertEnvCodeToEnvString,
  useGetProjectStorageInfo,
} from "@custom-hooks/Projects";
import { Project } from "@data-types/projects-types";
import OpenDialog from "@generic-components/OpenDialog";
import DotsHorizontalIcon from "@layouts/svg-icon/dots-horizontal-icon.svg";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@tw-components/ui/dropdown";
import { Link } from "@tw-components/ui/link";
import { NavbarItem } from "@tw-components/ui/navbar";
import { ProgressBar } from "@tw-components/ui/progress-bar";
import clsx from "clsx";
import { MutableRefObject, useRef } from "react";

/**
 * ProjectCard Component
 *
 * This component displays a card for a project, showing details like name, description,
 * region, storage, and interactive options for connecting, editing, or deleting the project.
 * If `demo` is provided, it simulates a project display without actual project data.
 *
 * @param {ProjectCardProps} props - Component properties
 * @param {Project | undefined} props.project - The project data, if available
 * @param {string} [props.width="tw-w-[36rem]"] - The width of the card
 * @param {boolean | DemoProps} [props.demo=false] - Demo properties for simulating project data
 * @returns {JSX.Element} The rendered ProjectCard component
 */

export type ProjectCardProps = {
  project?: Project | undefined;
  width?: string;
  demo?:
    | {
        ready: boolean;
        name: string;
        description: string;
        usedStorage: string;
        availableStorage: string;
        storagePercentage: number;
        nodes_count?: number;
      }
    | false;
};

export function ProjectCard({
  project = undefined,
  width = "tw-w-[36rem]",
  demo = false,
}: ProjectCardProps) {
  // Define project data based on `demo` or `project` input
  const finalProject = demo
    ? ({
        id: "",
        name: demo.name,
        description: demo.description,
        nodes_count: 1,
        regionsList: [
          {
            code: "us-west-1",
            description: "US West (N. California)",
          },
        ],
      } as Project)
    : project;

  // Destructure values from the final project data, with fallbacks
  const {
    id = "",
    name = "SQLite Cloud Project",
    description = "A description of the project.",
    nodes_count = 0,
    env = 0,
    regionsList,
  } = finalProject || {};

  // Convert env code to env string
  const envStatus = convertEnvCodeToEnvString(env);

  // Set the link based on project ID
  const href = id ? `/projects/${id}/nodes` : "#";

  // Fetch job data to determine project readiness
  const { isAnyJobInProgress, hasData: availableJobs } = useGetProjectJobs(id);
  const ready = demo
    ? demo.ready
    : availableJobs
      ? !(isAnyJobInProgress && !nodes_count)
      : true;

  // Fetch storage info for current project id
  const { data: storageInfo, hasData: availableStorageInfo } =
    useGetProjectStorageInfo(id);

  // Determine storage usage and availability
  const availableStorage = demo
    ? demo.availableStorage
    : availableStorageInfo
      ? storageInfo?.availableStorage
      : "";

  const usedStorage = demo
    ? demo.usedStorage
    : availableStorageInfo
      ? storageInfo?.usedStorage
      : "";
  const storagePercentage = demo
    ? demo.storagePercentage
    : availableStorageInfo
      ? storageInfo?.usedStoragePercentage
      : 0;
  const warning = availableStorageInfo ? storageInfo?.warning : false;

  // Dialog references for project actions
  const deleteProjectDialogRef = useRef<MutableRefObject<any | null>>(null);
  const editProjectDialogRef = useRef<MutableRefObject<any | null>>(null);
  const connectProjectDialogRef = useRef<MutableRefObject<any | null>>(null);

  // Render condition
  const showStorageIndicator = demo || availableStorageInfo;

  // Dropdown menu items with action handlers
  const dropdownItems = [
    {
      children: "Connect",
      disabled: !nodes_count,
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error OpenDialog not writter for typescript
        connectProjectDialogRef.current?.open();
      },
    },
    {
      children: "Edit details",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error OpenDialog not writter for typescript
        editProjectDialogRef.current?.open();
      },
    },
    {
      children: "Delete project",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error OpenDialog not writter for typescript
        deleteProjectDialogRef.current?.open();
      },
    },
  ];
  // Render ProjectCard component
  return (
    <>
      <Link
        href={href}
        className={clsx(
          // Size and interactivity
          width,
          "tw-cursor-pointer",
          // Styling
          "tw-rounded-2xl tw-border focus:tw-outline-none data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-2",
          "tw-bg-interface-card-light dark:tw-bg-interface-card-dark",
          "tw-dark:tw-border-interface-devider-dark tw-border-interface-devider-light",
          "tw-dark:hover:tw-border-interface-gray-light hover:tw-border-interface-gray-light",
          "tw-dark:data-[focus]:tw-outline-brandBlues-brandDark-dark data-[focus]:tw-outline-brandBlues-brandDark-light"
        )}
      >
        {/* Content */}
        <div className="tw-relative tw-h-full tw-w-full">
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-y-5 tw-overflow-hidden tw-px-5 tw-pb-5 tw-pt-3">
            {/* Header */}
            <div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-dark:tw-text-text-title-dark tw-text-16px-semiBold tw-text-text-title-light">
                  {name}
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
                  <div
                    className={clsx(
                      "tw-h-fit tw-rounded-2xl tw-px-3 tw-py-1",
                      "tw-dark:tw-text-text-title-dark tw-font-mono tw-text-12px-medium tw-lowercase tw-text-text-title-light",
                      env === 0 && "tw-dark:tw-bg-[#FEE0B4] tw-bg-[#FEE0B4]",
                      env === 1 &&
                        "tw-dark:tw-bg-brandBlues-ice-dark tw-bg-brandBlues-ice-light",
                      env === 2 && "tw-dark:tw-bg-[#C3E9BA] tw-bg-[#C3E9BA]"
                    )}
                  >
                    {envStatus.label}
                  </div>
                  <Dropdown>
                    <DropdownButton as={NavbarItem}>
                      <div className="tw-text-brandBlues-brandDark-light">
                        <DotsHorizontalIcon />
                      </div>
                    </DropdownButton>
                    <DropdownMenu className="min-w-64" anchor="bottom end">
                      {dropdownItems.map((item, i) => (
                        <DropdownItem
                          key={i}
                          onClick={item.onClick}
                          disabled={item.disabled}
                        >
                          <DropdownLabel>{item.children}</DropdownLabel>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div
                className={clsx(
                  "tw-dark:tw-text-text-subTitle-dark tw-mt-1 tw-text-14px-regular tw-text-text-subTitle-light sm:-tw-mt-1"
                )}
              >
                {/* Ensure job list availability to check if regions and nodes are in creation phase */}
                {availableJobs && (
                  <>
                    {Array.isArray(regionsList) && regionsList?.length > 0 ? (
                      <div className="tw-flex tw-flex-row tw-gap-x-2">
                        {regionsList.map((region) => (
                          <div key={region.code}>{region.code}</div>
                        ))}
                      </div>
                    ) : !isAnyJobInProgress ? (
                      <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                        No region for this project
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            {/* Description */}
            <div className="tw-dark:tw-text-text-subTitle-dark tw-line-clamp-2 tw-max-w-[24.75rem] tw-text-14px-light tw-text-text-subTitle-light">
              {description}
            </div>

            {/* Progress Bar for Storage Usage */}
            <div className="tw-flex-1" />
            <div className="tw-flex tw-max-w-[21rem] tw-flex-col tw-gap-y-2">
              {isAnyJobInProgress || nodes_count ? (
                <ProgressBar
                  value={storagePercentage as number}
                  variant={warning ? "error" : "base"}
                  type={ready ? "determinate" : "indeterminate"}
                />
              ) : (
                <></>
              )}
              <div className="tw-dark:tw-text-text-body-dark tw-text-14px-regular tw-text-text-body-light">
                {ready ? (
                  showStorageIndicator ? (
                    `${usedStorage} / ${availableStorage}`
                  ) : nodes_count ? (
                    <div className="tw-h-4 tw-w-20 tw-animate-pulse tw-rounded tw-bg-interface-gray-light dark:tw-bg-interface-gray-dark"></div>
                  ) : (
                    ""
                  )
                ) : (
                  "Creating project ..."
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* Connection Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={connectProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        <SingleProjectInfo projectId={id} />
      </OpenDialog>
      {/* Edit Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={editProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        {/* @ts-expect-error OpenDialog not writter for typescript */}
        <EditProject project={project} />
      </OpenDialog>
      {/* Delete Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={deleteProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        {/* @ts-expect-error OpenDialog not writter for typescript */}
        <DeleteProject project={{ name, id }} />
      </OpenDialog>
    </>
  );
}
